<template>
  <layout-vertical>

    <template v-slot:vertical-menu-header>
      <b-img
        :src="require('@/assets/images/logo/breeze_blue.svg')"
        alt="logo"
        class="header-image"
      />
    </template>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import { BImg } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BImg,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  &-image {
    height: 100%;
  }
}
</style>
